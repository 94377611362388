<template>
  <div>
    <div class="text-header mb-10">Enter password</div>
    <v-text-field
      label="Enter password"
      rounded
      filled
      color="grey"
      class="py-0 my-0"
      v-model="password"
      dense
      @click:append="showPassword = !showPassword"
      :type="showPassword ? 'text' : 'password'"
      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      hint="At least 8 characters"
    ></v-text-field>

    <v-btn
      class="sign-up-button my-4 white--text"
      rounded
      color="#00b08d"
      large
      block
      @click="signInByEmail()"
      :disabled="!isFormValid || isLoading"
      :loading="isLoading"
      >Sign In
    </v-btn>
    <div class="text-center my-3" @click="forgotPassword">
      <span class="text-underline forgot-password-link pointer">
        Forgotten your password?
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import AuthMixin from "@/mixins/AuthMixin.vue";
import { validateEmail } from "@/utilities/validateEmail.js";

export default {
  created() {
    // set the app bar title
    this.setCurrentAppBarTitle("Email Sign In");
    if (!this.emailOrPhoneNumber) {
      this.$router.push({ name: "SignIn" });
    }
  },
  mixins: [AuthMixin],

  data() {
    return {
      password: null,
      passwordConfirmation: null,
      showPassword: false,
      isLoading: false,
    };
  },
  computed: {
    isFormValid() {
      return this.password && this.password.length > 8;
    },
    emailOrPhoneNumber: {
      get: function () {
        return this.$store.getters["getEmailOrPhoneNumber"];
      },
      set: function (newVal) {
        this.$store.commit("setEmailOrPhoneNumber", newVal);
      },
    },
  },
  methods: {
    ...mapMutations(["setErrorSnackbarMessage", "setCurrentAppBarTitle"]),
    ...mapActions([
      "signInToFirebaseByEmail",
      "fetchUserFSDocument",
      "sendForgotPasswordEmail",
    ]),
    async signInByEmail() {
      this.isLoading = true;
      try {
        await this.signInToFirebaseByEmail({
          email: this.emailOrPhoneNumber,
          password: this.password,
        });

        await this.afterSignIn();
      } catch (error) {
        console.log(error);
        this.$alertsService.fireGeneralError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async forgotPassword() {
      if (!validateEmail(this.emailOrPhoneNumber)) {
        await this.setErrorSnackbarMessage("Invalid email");
      } else {
        this.sendForgotPasswordEmail(this.emailOrPhoneNumber);
      }
    },
  },
};
</script>
