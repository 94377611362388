
<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["fetchUserFSDocument"]),
    async afterSignIn() {
      const userDocument = await this.fetchUserFSDocument();
      // user has a subscription, redirect him to customer dashboard
      if (userDocument?.subscription) {
        this.$router.push({ name: "CustomerDashboard" });
      } else {
        this.$router.push({ name: "ChoosePlan" });
      }
    },
  },
};
</script>
